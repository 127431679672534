@import "mixins/fontface";
@import "mixins/icon-bullet";

html {
  @include font-size($root-font-size);
}

@include font-face(Fengardoneue, /assets/fonts/Fengardoneue/fengardoneue_regular, 400, normal);
@include font-face(Fengardoneue, /assets/fonts/Fengardoneue/fengardoneue_black, 800, bold);

// @include font-face(Fanwood, /assets/fonts/Fanwood/fanwood, 400, normal);

// @include font-face(Markazi, /assets/fonts/Markazi/MarkaziText-Regular);

// @include font-face(HappyTimes, /assets/fonts/HappyTimes/happy-times-NG_regular_master_web,400,normal);
// @include font-face(HappyTimes, /assets/fonts/HappyTimes/happy-times-NG_italic_master_web,400,italic);
// @include font-face(HappyTimes, /assets/fonts/HappyTimes/happy-times-NG_bold_master_web,800,bold);

// @include font-face(LeagueSpartan, /assets/fonts/LeagueSpartan/LeagueSpartan-Extralight,100,normal);
// @include font-face(LeagueSpartan, /assets/fonts/LeagueSpartan/LeagueSpartan-Light,200,normal);
// @include font-face(LeagueSpartan, /assets/fonts/LeagueSpartan/LeagueSpartan-Regular,400,normal);
// @include font-face(LeagueSpartan, /assets/fonts/LeagueSpartan/LeagueSpartan-Medium,500,normal);
// @include font-face(LeagueSpartan, /assets/fonts/LeagueSpartan/LeagueSpartan-Semibold,600,bold);
// @include font-face(LeagueSpartan, /assets/fonts/LeagueSpartan/LeagueSpartan-Bold,700,bold);
// @include font-face(LeagueSpartan, /assets/fonts/LeagueSpartan/LeagueSpartan-Black,800,bold);

@include font-face(Compagnon, /assets/fonts/Compagnon/Compagnon-Bold,800,bold);

@include font-face(Victorianna, /assets/fonts/Victorianna/vtf_victorianna_thin-webfont, 500, normal);
@include font-face(Victorianna, /assets/fonts/Victorianna/vtf_victorianna_thin_talic-webfont, 500, italic);

body {
    background: url('/img/texture.png'), $yellowbg;
    background-size: 80%;
    background-repeat: repeat;
}

.compagnon {
    font-family: Compagnon;
    font-weight: 800;
}

.victorianna {
    font-family: Victorianna;
    font-weight: 500;
    font-style: italic;
}

p {
    font-family: $fontCopy;
}

h1 {
    color: $h1-color;
}

.logo-nav {


    height: 7vw;

    @include media-breakpoint-up (sm) {
        height: 4vw;
    }
    @include media-breakpoint-up (md) {
        height: 3vw;
    }
    @include media-breakpoint-up (lg) {
        height: 2.5vw;
    }
    @include media-breakpoint-up (xl) {
        height: 2vw;
    }
}


.intro-logoPartenaires {
    font-weight: 400;
    font-family: Fengardoneue;
    letter-spacing: 0.15rem;
    //text-transform: uppercase;
    color: $blueindie;
}

.nav-link span{
    color: $blueindie;
}

.nav-item.show{
    >a{
        background-color: $blueindie;
        width: fit-content;
        border-radius: 0.25rem;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    span{
        color: $white;
        display: inline-block;
        transform: rotate(45deg);
    }
    .nav-link{
        color: $white!important;
    }
    .menu::before{
        color: $white!important;
    }
}

.dropdown-item.alert-info{
    background-color: $info-color;
}

.nav-dropdown-item{
    width: fit-content; 
    border: 1px solid $blueindie;
    background-color: $white;
    border-radius: 0.25rem;
    margin-bottom: 0rem!important;
}

.dropdown-menu{
    background-color:transparent;
    border: none;
    margin:0;
    padding: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
    background-color: $gray-color;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus{
    color: $blueindie;
}

.logoPartenaires {
	opacity: 0.7;
    padding: 0.5rem;
    .img-fluid {
        max-width: 3rem;
    }
}


.btn-outline-primary:hover, .btn-outline-primary:focus{
    color: $white!important;
    ::before{
        color: $white!important;
    } 
}

.btn-outline-primary:hover::before, .btn-outline-primary:focus::before{
    color: $white!important;
}

#nav-apps{
    background-color: $white;
    transition: opacity 0.3s ease, transform 0.3s ease;
}

#nav-apps.fixed {
    left: 2%;
    right: 2%;
    position: fixed;
    top: 0;
    z-index: 1000;
    transform: translateY(0);
    opacity: 1;
    width: 96%;
    padding: 0;
     
    li{
        margin: 0;
     }
}

#nav-apps.hidden {
    transform: translateY(-100%);
    opacity: 0;
}

a.menu::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    width: 1.4rem;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "";
    color: $black;
    margin-right: 0.1rem;
}

// icône qui signifie les liens externes
.fa-external-link-alt {
    font-size:70%;
}

.hal {
    display: none;
}
