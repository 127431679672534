$white: rgb(255, 255, 255);
$black: rgba(0, 0, 0, 0.8);
$blueindie: #0db4c7;
$info-color: #d1ecf1;
$yellowbg: #fdfadb;
$gray-color: #d4dcdd;
$body-bg: $yellowbg;
$body-color: $black;
$enable-responsive-font-sizes: true;

/* custom vars */
$root-font-size: 20px;
$font-family-sans-serif: "Fengardoneue", Roboto, "Helvetica Neue", sans-serif !default;
$font-family-base: $font-family-sans-serif !default;

// golden ratio
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$h1-font-size: $font-size-base * 4.236 !default;
$h2-font-size: $font-size-base * 2.618 !default;
$h3-font-size: $font-size-base * 1.618 !default;
$h4-font-size: $font-size-base !default;
$h5-font-size: $font-size-base / 1.618 !default;
$h6-font-size: $font-size-base / 2.618 !default;

$headings-line-height:        1.2 !default; // je ressere aussi ici

// $h1-font-size:3.375rem;
// $h2-font-size:2.25rem;
// $h3-font-size:1.5rem;
// $h4-font-size:1rem;
// $h5-font-size:0.667rem;

$font-weight-lighter:         100 !default;
$font-weight-light:           200 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            800 !default;
$font-weight-bolder:          900 !default;

$fontCopy: "Fengardoneue", Roboto, "Helvetica Neue", sans-serif !default;
$line-height-base:            1.4 !default; // je ressere un peu, plus adapté à cette font

$h1-color: $blueindie;
$subheaders-color: $black;

$primary:       $blueindie !default;