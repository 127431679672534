// =============================================================================
// Mixins for inserting icons in place of bullets for li elements (by ImaCrea)
// =============================================================================


@mixin icon-bullet($unicode,$FAref,$color: $blueindie) {
    list-style-type: none;

    li {

        font-family: $fontCopy;
        text-indent:-1.5rem;
        padding-left: 0;

        &::before {
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;   
        -webkit-font-smoothing: antialiased;
        float: left;
        width: 1.4rem;
        font-family: $FAref; font-weight: 900; content: $unicode;
        color: $color;
        margin-right: 0.1rem;
        }
    }
}