@import "variables";
@import "bootstrap/scss/bootstrap";
@import "main";

$duration: .2s;
$distance: 8px;
$easeOutBack: cubic-bezier(0.175, 0.885, 0.320, 1.275);

.hero {
    h1 {
        font-family: Fengardoneue;
        font-weight: 900;
        line-height: 1.2;

        @include font-size (4rem);

        color: transparent;
        animation: showMe 300ms ease-in;
        animation-fill-mode: forwards;
        animation-delay:500ms;

        @include media-breakpoint-down (lg) {
            margin-top: 0 !important;
        }

    }

    p:nth-of-type(1) {
        @include font-size (1.218rem);

        font-weight: 400;
        color: transparent;
        animation: showMe 300ms ease-in;
        animation-fill-mode: forwards;
        animation-delay:800ms;
    }

    p:nth-of-type(2) {
        @include font-size (1rem);

        font-family: Fengardoneue;
        font-weight: 800;
        letter-spacing: 0.03rem;

        color: transparent;
        animation: showMe 300ms ease-in;
        animation-fill-mode: forwards;
        animation-delay:1000ms;
    }
}

img {
    opacity: 0;
    animation: showMeImg 900ms ease-in;
    animation-fill-mode: forwards;
    animation-delay:500ms;
}

@keyframes showMe {
    0% {color: transparent;}
  100% {color: $h1-color;}
}

@keyframes showMeImg {
    0% {opacity: 0;}
  100% {opacity: 1;}
}

h1 {
    font-weight: 800 !important;
}
h2 {
    font-weight: 400;
}
h3 {
    font-weight: 800 !important;
    letter-spacing: 0.05rem;
}

// .demo {
// 	background-image: url(/img/fullsize/HP-2.png);
// 	background-size: 90%;
// 	background-repeat: no-repeat;
// 	background-position: center 6rem;
// 	padding-top:25%
// }

.zen {
    @include media-breakpoint-up(md) {
        max-height: 20rem;
    }
    @include media-breakpoint-up(lg) {
        max-height: 35rem;
    }

};

.accompagnement {
    @include media-breakpoint-up(md) {
        max-height: 20rem;
    }
    @include media-breakpoint-up(lg) {
        max-height: 35rem;
    }
};

.different {
    position:relative;

    a {
        position:absolute;
        top: 48%;
        left: 50%;
        transform: translate(-50%,-50%); // permet de positionner le point d'ancrage au centre de l'élément
        box-shadow: 0px 0px 0px 5px $yellowbg;
    }
}

// Animation d'intro liiibre.monasso.org - Reliée à un script JS pour modifier son contenu
.hey {
    $delaystart: 1200ms;
    $delaybetweenchars: 120ms;
    $typingduration: 50ms;
    $charsstatic: 8;
    $charschanging: 12;
    $charstotal: $charsstatic + $charschanging; 
    $delayend: 3500ms;

    .static {
        color: transparent;
        animation: staticAnim $typingduration ease-out;
        animation-fill-mode: forwards;

        @for $i from 1 through $charsstatic {
            &:nth-of-type(#{$i}) {
                animation-delay: $delaystart + $delaybetweenchars * $i;
            }
        }
    }

    .changing {
        color: transparent;
        animation-fill-mode: forwards;

        @for $i from ($charsstatic + 1) through $charstotal {
            &:nth-of-type(#{$i}) {
                animation: changingAnim $typingduration + ( $charstotal - $i ) * ( $typingduration + $delaybetweenchars ) + $delayend ease-out;
                animation-delay: $delaystart + $delaybetweenchars * $i;
            }
        }
    }
}

@keyframes staticAnim {
    0% {color: transparent;}
  100% {color: $h1-color;}
}

@keyframes changingAnim {
    0% {color: transparent;}
    5% {color: $h1-color;}
    95% {color: $h1-color;}
    100% {color: transparent;}
}

.outro {
    h1 {@include font-size($h2-font-size);}
}


#form-merci, #form-oups, #form-merci:target ~ form, #form-oups:target ~ form {
    display: none;
}

#form-merci:target, #form-oups:target {
    display: block;
}
